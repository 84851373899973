<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Write From Dictation</h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Write From Dictation</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a sentence. Type the sentence in the box exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 3 - 4 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The audio begins to play automatically. You are only able to listen to the audio recording once.</li>
                <li>While the audio is playing, you can take notes on the erasable noteboard provided.</li>
                <li>Type as many correct words as you can remember into the response box in the correct order.</li>
                <li>You will be judged for Listening and writing.</li>
            </ul>
            </template>
            <app-ListeningExtraInfo/>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Write From Dictation</h4>
            <p class="questHeading">You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblDectationQuestion'"/>
            </div>
            </div>
            <div v-if="questionrecording" class="audio-container mb-5"><audio id="questionRec" :src="questionrecording" controls /></div>
            <div class="col-12 pr-5 pt-4">
            <textarea spellcheck="false" data-gramm="false" rows="8" id='dictationAnswer' class="textAreaSingleLine m-3" v-model="inputText" @keydown="recheckInput"></textarea>
            </div>
            <p style="display:block;"><strong>Word Count: {{wordCount}}</strong></p>

                <div class="row">
                <div class="col-12 text-left mt-1 mb-2" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left: 0px !important; color: rgb(255, 255, 255);line-height:  unset !important;"><strong>Your Score:   <span class="pl-1">{{marksGot}}</span>/{{totalMarks}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li class="rightAnswer"><strong> {{selectedquestion.transcript}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>

             <!-- <div class="row wordCountMargin">
                <div class="col-12 mb-4 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your question result is {{this.marksGot}} out of {{this.totalMarks}}</strong></p>
                </div>
                 <div class="col-12 text-center mt-3" v-if="selectedquestion">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
                    <p style="padding-left:0px!important;" v-if="checkAnswerFlag == true">Answer: <strong>{{selectedquestion.transcript}}</strong></p>
                </div>
            </div> -->
        </div>
       <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserDectationAnswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
import ListeningExtraInfo from '../reuseable/listeningInformation.vue'
import $ from "jquery";
//import LocalStorageService from '../../store/LocalStorageService'
export default {
    components:{
        'app-Timer':Timer,
 'app-IntervalList': IntervalList,
 'app-QuestionStatus':QuestionStatus,
 'app-DiscussionTab':DiscussionTab,
 'app-ListeningExtraInfo':ListeningExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            inputText:'',
            wordCount:0,
            limitCharter: 70,
            limitDone:false,
            charCount:0,
            questionrecording: null,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answer_desc:'',
                mock_test_id:0,
                user_score:''
            },
            totalMarks:0,
            marksGot:0,
            checkAnswerFlag: false,
            user_type:'Free',
            currentQuestion:0,
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.resetAll()
            this.userAnswer.question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            //console.log(this.selectedquestion)
            //this.questionservice('configdectationquestion/blobId='+questionId).then(()=>{
                var binary= this.selectedquestion.blb //this.getQuestionList().data.blb;
                this.questionrecording = binary;
                this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)

                this.$refs.timer.setTime(3)
                });
            //}); 
        },
        async checkAnswer(){
            this.totalMarks = 0
            this.marksGot = 0
            var answerWords = this.selectedquestion.transcript.split(' ');
            //console.log(this.selectedquestion)
            this.totalMarks = answerWords.length
            if(this.inputText == '') return this.$alert(
                            "Please enter your answer!",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "OK"
                            })
            this.checkAnswerFlag = true
            var vm= this
            $.each(answerWords,function(index,item){
                //console.log(item)
                if(item=='.'|item==',') vm.marksGot+1
                else 
                {
                    item=item.replace('.','').replace(',','')
                    vm.inputText=vm.inputText.replace('.','').replace(',','')
                    vm.marksGot=(vm.inputText.trim().toLowerCase().includes(item.trim().toLowerCase()))? vm.marksGot+1: vm.marksGot;
                }
            })
        },
        recheckInput:function(evt){
            if(evt.keyCode == 32){
            if(this.limitCharter == this.wordCount)
            {
                this.limitDone = true;
                    this.charCount = this.inputText.length	
            }
            }
                if(this.limitDone & evt.keyCode !=32){
                    this.limitDone = false;
            }
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        timerEnd(){
           
               var aud = document.getElementById("questionRec");
               aud.play()
               this.$nextTick(function(){
                   this.title = 'Time'
                this.$refs.timer.setTime(0)
                })
            // aud.onended = function() {
            //     $('.ar-icon.ar-icon__lg').click()
            //     }
        },
        async submitanswer() {
            await this.checkAnswer()
            var vm = this;
            this.userAnswer.answer_desc = this.inputText;
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.mock_test_id=0
            this.userAnswer.user_score='Your Score: '+this.marksGot+'/'+this.totalMarks;
            this.userAnswer.url = 'userdectationanswer/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
            this.answerSubmit = true
                
        },
        resetAll(){
            this.inputText = '';
            this.userAnswer = {
                url:'',
                question_id:0,
                answer_desc:'',
                mock_test_id:0,
                user_id:this.$ls.get('user').user_id,
                user_score:''
            }
             this.totalMarks = 0
            this.marksGot = 0
            this.checkAnswerFlag = false
            this.answerSubmit = false
        }
    },
    beforeMount(){
        this.questionLists=(this.$ls.get('tblDectationQuestion')!=null)?JSON.parse(this.$ls.get('tblDectationQuestion')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            }
            else{
        this.questionservice('configdectationquestion/getallquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        });
    }
    },
    watch: {
        inputText: function (val) {
        if(this.limitDone & this.charCount <= val.length) {
        this.inputText = this.inputText.substr(0, this.charCount)
        }
        if(this.charCount > val.length){
        this.limitDone = false;
        }
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    }
}
</script>
<style scoped>
.wordCountMargin{
    margin-top: 10px!important;
    margin-left: 5px!important;
}
</style>